var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        attrs: { "previous-location": "", "page-title": "Assessment" },
      }),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.url
            ? _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              })
            : _c("iframe", {
                staticClass: "dimensions",
                attrs: { src: _vm.url },
              }),
          _c("div", { staticClass: "opaque-cover" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }