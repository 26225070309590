<template>
  <v-container>
    <page-title previous-location page-title="Assessment"></page-title>
    <v-row justify="center">
      <v-progress-circular
        v-if="!url"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <iframe v-else :src="url" class="dimensions"></iframe>
      <div class="opaque-cover"></div>
    </v-row>
  </v-container>
</template>

<script>
import courseworkService from "#ef/assignment/services/CourseworkService";
import projectService from "#ef/project/services/ProjectService";
export default {
  name: "MAssessment",
  props: {
    type: String,
    object_id: String,
    index: String,
  },
  data() {
    return { url: undefined };
  },
  mounted() {
    this.fetchAssessment();
  },
  methods: {
    async fetchAssessment() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        if (this.type == "assignment") {
          let submissions = await courseworkService.getSubmissions(
            this.object_id,
          );
          this.url = submissions[0].assessed_url[Number(this.index)];
        } else if (this.type == "project") {
          let submissions = await projectService.getSubmissions(this.object_id);
          let submissionType = this.index.startsWith("step") ? "step" : "whole";
          let i = this.index.split("-")[1];
          if (submissionType == "whole") {
            this.url = submissions.submissions[0].assessed_url[i];
          } else {
            let j = this.index.split("-")[0].slice(4);
            this.url = submissions.step_submissions[j].assessed_url[i];
          }
        } else {
          // in case of exam
          let examHistoryDetails = await this.$store.dispatch(
            "studentExam/getExamHistoryDetails",
            { studentExamId: this.object_id },
          );
          // in case of exam, index is the question id
          this.url = examHistoryDetails.answers[this.index].assessed_url;
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dimensions {
  width: 90%;
  height: 75vh;
  position: relative;
  top: 20px;
}
.opaque-cover {
  opacity: 0;
  height: 60px;
  width: 40%;
  left: 60%;
  position: absolute;
  @media screen and (max-width: 500px) {
    left: 0;
    width: 100%;
  }
}
</style>
